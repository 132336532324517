import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import config from './firebase.config'
import axios from 'axios'
import jwt from './jwt/useJwt'
import { firebaseAuthUrl } from '../configs/api'

let userResolvedTimer = null

const initFirebase = () => {
  const app = firebase.initializeApp(config[process.env.REACT_APP_PRCZ_ENV])
  //firebase.firestore.setLogLevel("debug")
  window.prcz = {
    firebase,
    firebaseApp: app
  }
}

const updateJwt = (onTokenRefresh) => jwt.refreshToken(true).then(token => {
  jwt.updateAllTokens(token)
  window.setTimeout(() => onTokenRefresh?.(token), 250)
})
const getFirebaseApp = () => firebase.apps[0]

const getFirestoreDb = () => {
  const app = getFirebaseApp()
  return firebase.firestore(app)
}

const getCurrentUser = () => firebase.auth().currentUser

const getCurrentCompany = () => {
  const currUserEmail = getCurrentUser()?.email
  if (!currUserEmail) {
    return null
  }
  const db = getFirestoreDb()

  return db.collection('companies').doc(currUserEmail)
}

const onNewCompanyRegistered = (company, params, onComplete) => {
  updateJwt(() => {
    company.set(params)
    axios.post(firebaseAuthUrl, { providerId: getCurrentUser().uid })
      .then(() => updateJwt(() => onComplete?.()))
  })
}

const signUpUsingEmail = ({ email, password, fullName }, successFn, failureFn) => {
  return firebase.auth().createUserWithEmailAndPassword(email, password)
    .then((userCredential) => {
      // Signed in
      userCredential.user.updateProfile({
        displayName: fullName
      })

      // We use this verification for cross provider authentication
      // Example: User sign up using Google and then sign in using Email. If the company already exists, we show an error
      const company = getCurrentCompany()
      company.get().then((doc) => {
        if (doc.exists) {
          failureFn?.('Company already registered')
        } else {
          // doc.data() will be undefined in this case
          onNewCompanyRegistered(company, {
            fullName,
            email,
            loginProvider: 'email'
          }, successFn)
        }
      }).catch((error) => {
        console.error("Error getting document:", error)
        failureFn?.(error)
      })
    })
    .catch((error) => {
      console.error(error)
      failureFn?.(error.message)
    })
}


const typesProviders = {
  microsoft: new firebase.auth.OAuthProvider('microsoft.com'),
  gmail: new firebase.auth.GoogleAuthProvider()
}

const signInOauthFactory = type => (successFn, newUserFn, failureFn, finallyFn) => {
  const provider = typesProviders[type]

  return firebase.auth()
    .signInWithPopup(provider)
    .then(() => {
      /** @type {firebase.auth.OAuthCredential} */
      const userCredential = getCurrentUser()
      const company = getCurrentCompany()
      company.get().then((doc) => {
        if (doc.exists) {
          successFn?.(doc.data())
        } else {
          // doc.data() will be undefined in this case
          onNewCompanyRegistered(company, {
            fullName: userCredential.displayName,
            email: userCredential.email,
            loginProvider: type
          }, () => newUserFn?.(userCredential))
        }
        // ...
      }).catch((error) => {
        failureFn?.(error.message)
      })
        .finally(() => {
          finallyFn?.()
        })
    })

}

const signUpOauthFactory = type => (successFn, failureFn, finallyFn) => {
  const provider = typesProviders[type]

  return firebase.auth()
    .signInWithPopup(provider)
    .then(() => {
      /** @type {firebase.auth.OAuthCredential} */
      const userCredential = getCurrentUser()
      const company = getCurrentCompany()
      company.get().then((doc) => {
        if (doc.exists) {
          failureFn?.('Company already registered')
        } else {
          // doc.data() will be undefined in this case
          onNewCompanyRegistered(company, {
            fullName: userCredential.displayName,
            email: userCredential.email,
            loginProvider: type
          }, () => successFn?.(userCredential))
        }
        // ...
      }).catch((error) => {
        failureFn?.(error.message)
      })
        .finally(() => {
          finallyFn?.()
        })
    })

}

const signUpUsingGmail = signUpOauthFactory('gmail')
const signInUsingGmail = signInOauthFactory('gmail')

const signUpUsingMicrosoft = signUpOauthFactory('microsoft')
const signInUsingMicrosoft = signInOauthFactory('microsoft')

const signInUsingEmail = ({ email, password }, successFn, failureFn, finallyFn) => {
  return firebase.auth().signInWithEmailAndPassword(email, password)
    .then(() => {
      updateJwt()
      // Signed in
      successFn?.()
    })
    .catch((error) => {
      failureFn?.(error.message)
      finallyFn?.()
    })
}

const sendPasswordResetEmail = (email, successFn, failureFn) => {
  return firebase.auth().sendPasswordResetEmail(email)
    .then(() => {
      successFn?.()
    })
    .catch((error) => {
      console.error(error)
      failureFn?.(error.message)
    })
}

const logoutFromSession = () => {
  return firebase.auth().signOut()
}

const getUserClaims = async (successFn, timeout = 0) => {
  userResolvedTimer = setTimeout(async () => {
    clearTimeout(userResolvedTimer)
    const preciseUser = await firebase.auth().currentUser.getIdTokenResult()
    if (preciseUser?.claims?.precise_id) {
      successFn(preciseUser.claims)
    } else {
      getUserClaims(successFn, 1000)
    }
  }, timeout)
}

export {
  initFirebase,
  logoutFromSession,
  getFirebaseApp,
  getCurrentUser,
  signInUsingEmail,
  signUpUsingEmail,
  signUpUsingGmail,
  signInUsingGmail,
  signUpUsingMicrosoft,
  signInUsingMicrosoft,
  getCurrentCompany,
  sendPasswordResetEmail,
  getUserClaims
}
