// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

const initialState = {
  userData: initialUser(),
  user: false,
  userLoading: true,
  initiated: undefined
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    handleLogin: (state, action) => {
      state.user = action.payload
    },
    setUser: (state, action) => {
      state.user = action.payload
      state.userLoading = false
    },
    handleLogout: (state) => {
      window.localStorage.removeItem('prcz-email')
      window.localStorage.removeItem('prcz-step')
      state.initiated = false
      useJwt.removeToken()
    },
    setInitiated: (state) => {
      state.initiated = true
    }
  }
})

export const { handleLogin, handleLogout, setUser, setInitiated } = authSlice.actions
export const authInitialState = initialState

export default authSlice.reducer
