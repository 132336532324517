// ** React Lazy withou flickering
import { lazy } from 'react-lazy-no-flicker'

const Accounting = lazy(() => import('../../views/accounting'))

const AccountingRoutes = [
  // Accounting
  {
    path: '/accounting',
    element: <Accounting />,
    meta: {
      secured: true
    }
  }
]

export default AccountingRoutes
