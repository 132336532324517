import { DefaultRoute } from '../router/routes'
import firebase from 'firebase/app'
import 'firebase/auth'

// ** Spinner (Splash Screen)
import Spinner from '@components/spinner/Fallback-spinner'

// ** React Lazy withou flickering
import { usePastDelay } from 'react-lazy-no-flicker'

// ** Checks if an object is empty (returns boolean)
export const isObjEmpty = obj => Object.keys(obj).length === 0

// ** Returns K format from a number
export const kFormatter = num => (num > 999 ? `${(num / 1000).toFixed(1)}k` : num)

// ** Converts HTML to string
export const htmlToString = html => html.replace(/<\/?[^>]+(>|$)/g, '')

// ** Checks if the passed date is today
const isToday = date => {
  const today = new Date()
  return (
    /* eslint-disable operator-linebreak */
    date.getDate() === today.getDate() &&
    date.getMonth() === today.getMonth() &&
    date.getFullYear() === today.getFullYear()
    /* eslint-enable */
  )
}

/**
 ** Format and return date in Humanize format
 ** Intl docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/format
 ** Intl Constructor: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/DateTimeFormat
 * @param {String} value date to format
 * @param {Object} formatting Intl object to format with
 */
export const formatDate = (value, formatting = { month: 'short', day: 'numeric', year: 'numeric' }) => {
  if (!value) return value
  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

// ** Returns short month of passed date
export const formatDateToMonthShort = (value, toTimeForCurrentDay = true) => {
  const date = new Date(value)
  let formatting = { month: 'short', day: 'numeric' }

  if (toTimeForCurrentDay && isToday(date)) {
    formatting = { hour: 'numeric', minute: 'numeric' }
  }

  return new Intl.DateTimeFormat('en-US', formatting).format(new Date(value))
}

/**
 ** Return if user is logged in
 ** This is completely up to you and how you want to store the token in your frontend application
 *  ? e.g. If you are using cookies to store the application please update this function
 */
export const isUserLoggedIn = () => {
  const anonymousUser = firebase.auth().currentUser

  return !!anonymousUser
}
export const getUserData = () => JSON.parse(localStorage.getItem('userData'))

/**
 ** This function is used for demo purpose route navigation
 ** In real app you won't need this function because your app will navigate to same route for each users regardless of ability
 ** Please note role field is just for showing purpose it's not used by anything in frontend
 ** We are checking role just for ease
 * ? NOTE: If you have different pages to navigate based on user ability then this function can be useful. However, you need to update it.
 * @param {String} userRole Role of user
 */
export const getHomeRouteForLoggedInUser = onboarded => {
  return onboarded ? DefaultRoute : '/register'
}

// ** React Select Theme Colors
export const selectThemeColors = theme => ({
  ...theme,
  colors: {
    ...theme.colors,
    primary25: '#7367f01a', // for option hover bg-color
    primary: '#7367f0', // for selected option bg-color
    neutral10: '#7367f0', // for tags bg-color
    neutral20: '#ededed', // for input border-color
    neutral30: '#ededed' // for input hover border-color
  }
})

export const getRegisterViewByCompanyData = (companyData, page) => {
  if (companyData) {
    const localStorageStep = localStorage.getItem('prcz-step')
    if (companyData.phone && !companyData.address) {
      return 'time'
    }
    if (!companyData.maxAmount && companyData.maxAmount !== null) {
      return 'amount'
    }
    if (page === 'complete') {
      return 'complete'
    }
    if (page === 'accounting') {
      return 'accounting'
    }
    if (page === 'commerce') {
      return 'commerce'
    }
    if (!companyData.customerId && !companyData.created) {
      return 'company'
    }
    if (localStorageStep) {
      return localStorageStep
    }
    if (companyData.status !== 'ONBOARDED') {
      return 'bank'
    }
    return 'verified'
  }
  return 'register'
}

export const getOnboardingStageByLead = (lead, user) => {
  if (lead) {
    if (lead.phone && !lead.address) {
      return 'SCHEDULED MEEETING'
    }
    if (!lead.maxAmount && lead.maxAmount !== null) {
      return 'SIGNED UP'
    }
    if (!lead.customerId && !lead.created) {
      return 'LEAD CREATED'
    }
    if (lead.status !== 'ONBOARDED') {
      return 'COMPANY CREATED'
    }
    return 'ONBOARDED'
  }
  if (user) {
    return 'SIGNED UP'
  }
  return 'NOT SIGNED UP'
}


export const normalizeCurrency = (num) => (typeof num === 'string' ? parseFloat(num) : num).toLocaleString('en-us', { maximumFractionDigits: 0, style: 'currency', currency: 'USD' })
export const toNiceDate = date => new Date(date).toLocaleString('en-us', { weekday: 'long', month: 'long', day: 'numeric' })
export const toShortDate = date => new Date(date).toLocaleString('en-us', { year: 'numeric', month: 'numeric', day: 'numeric' })
export const toShortDateTime = date => new Date(date).toLocaleString('en-us', { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })

export const Loading = () => {
  const past_delay = usePastDelay(400)
  if (!past_delay) return null
  return <Spinner />
}

export const isProduction = process.env.REACT_APP_PRCZ_ENV === 'prod'
export const minimumTopup = 10000

export const onInit = (owner) => {
  owner.lastInputValue = ''
}