// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getAccountingIntegrationsUrl, getAccountingUrl } from '@src/configs/api'

// ** Axios Imports
import axios from 'axios'

export const releaseError = createAction('accounting/releaseError')
export const selectIntegration = createAction('accounting/selectIntegration')

export const getAccountingIntegrations = createAsyncThunk('accounting/getAccountingIntegrations', async customerId => {
    const response = await axios.get(getAccountingIntegrationsUrl(customerId))
    return { data: response.data }
})

export const getAccountingCustomer = createAsyncThunk('accounting/getAccountingCustomer', async (customerId, data) => {
    const response = await axios.get(getAccountingUrl(customerId), data)
    return { data: response.data }
})

export const createAccountingCompany = createAsyncThunk('accounting/createAccountingCompany', async (data) => {
    const response = await axios.post(getAccountingUrl(data.customerId), data)
    return { data: response.data }
})

export const initialState = {
    integrations: undefined,
    selectedIntegration: undefined,
    company: undefined,
    error: undefined,
    inProgress: undefined
}

export const accountingSlice = createSlice({
    name: 'accounting',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(releaseError, state => {
                state.error = undefined
            })
            .addCase(getAccountingIntegrations.rejected, (state) => {
                state.error = 'Failed to load Accounting Platforms'
            })
            .addCase(getAccountingIntegrations.fulfilled, (state, action) => {
                state.error = undefined
                state.integrations = action.payload.data
            })
            .addCase(getAccountingCustomer.rejected, (state) => {
                state.error = 'Failed to get Accounting information'
            })
            .addCase(getAccountingCustomer.fulfilled, (state, action) => {
                state.error = undefined
                state.company = action.payload.data
            })
            .addCase(createAccountingCompany.pending, (state) => {
                state.inProgress = true
                state.error = undefined
            })
            .addCase(createAccountingCompany.rejected, (state) => {
                state.error = 'Failed to link Accounting Platform'
                state.inProgress = false
            })
            .addCase(createAccountingCompany.fulfilled, (state, action) => {
                state.error = undefined
                state.company = action.payload.data
                state.inProgress = false
            })
            .addCase(selectIntegration, (state, action) => {
                state.selectedIntegration = action.payload
            })
    }
})

export const accountingInitialState = initialState
export default accountingSlice.reducer
