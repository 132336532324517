import { createContext, useContext } from 'react'
import { grafanaFaroTracing } from '../../tracing/GrafanaFaro'

// const TracingID = ''

const Tracing = createContext()
const defaultTracing = grafanaFaroTracing

const TracingContext = (props) => {

    const provider = new (props.type || defaultTracing)(props)

    const initTracing = (instance) => {
        if (!provider.enabled) {
            return
        }
        provider.init(instance)
    }

    const trace = (type, payload) => {
        if (!provider.enabled) {
            return
        }
        provider.trace(type, payload)
    }

    const removeTracing = () => {
        if (!provider.enabled) {
            return
        }
        provider.remove()
    }

    return <Tracing.Provider value={{ initTracing, trace, removeTracing }} {...props} />
}

const useTracing = () => useContext(Tracing)

export { TracingContext, useTracing }