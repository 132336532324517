// ** React Imports
import { Navigate } from 'react-router-dom'
import { Suspense } from 'react'
import { isUserLoggedIn } from '@utils'
import { useSelector } from 'react-redux'

// ** Context Imports
// import { AbilityContext } from '@src/utility/context/Can'

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  // const ability = useContext(AbilityContext)
  // const { user } = useSelector(state => state.auth)
  const { userOnboarded } = useSelector(state => state.customers)

  const userLoggedIn = isUserLoggedIn()
  const showDashboard = userLoggedIn && userOnboarded

  if (route) {
    // let action = null
    // let resource = null
    // let restrictedRoute = false

    // if (route.meta) {
    //   action = route.meta.action
    //   resource = route.meta.resource
    //   restrictedRoute = route.meta.restricted
    // }
    if (!userLoggedIn && route.meta && route.meta.secured) {
      localStorage.setItem('prcz-redirectTo', window.location.pathname + window.location.search)
      return <Navigate to={{
        pathname: '/login',
        state: { redirectTo: window.location.pathname + window.location.search }
      }} />
    }
    if (!showDashboard && (!route.meta || (!route.meta.authRoute && !route.meta.publicRoute))) {
      return <Navigate to='/register' />
    }
    if (showDashboard && route.meta?.authRoute && !route.meta?.oAuth) {
      return <Navigate to='/' />
    }
    // if (user && restrictedRoute && user.role === 'client') {
    //   return <Navigate to='/access-control' />
    // }
    // if (user && !ability.can(action || 'read', resource)) {
    //   return <Navigate to='/misc/not-authorized' replace />
    // }
  }

  return <Suspense fallback={null}>{children}</Suspense>
}

export default PrivateRoute
