const PRCZ_BASE_API_ENVS = {
  demo: 'dev',
  dev: 'api.dev',
  prod: 'api'
}

const PRCZ_API_BASE = `https://${PRCZ_BASE_API_ENVS[process.env.REACT_APP_PRCZ_ENV]}.precise.credit`

const PRCZ_BASE_ENVS = {
  demo: 'demo',
  dev: 'my.dev',
  prod: 'my'
}

export const baseUri = `https://${PRCZ_BASE_ENVS[process.env.REACT_APP_PRCZ_ENV]}.precise.credit`

const CHECKBOOK_ENVS = {
  demo: 'SANDBOX',
  dev: 'SANDBOX',
  prod: 'PROD'
}

const CHECKBOOK_SUBDOMAIN = {
  demo: 'sandbox.',
  dev: 'sandbox.',
  prod: ''
}

export const checkbookEnv = CHECKBOOK_ENVS[process.env.REACT_APP_PRCZ_ENV]
export const checkbookUrl = `https://${CHECKBOOK_SUBDOMAIN[process.env.REACT_APP_PRCZ_ENV]}checkbook.io/embed/vcc.js`

export const firebaseAuthUrl = `${PRCZ_API_BASE}/users/firebase`
export const leadUrl = `${PRCZ_API_BASE}/financing/leads`
export const proxyUrl = `${PRCZ_API_BASE}/features/proxy`
export const getLeadOnboardedUrl = leadId => `${leadUrl}/${leadId}/onboarded`
export const customersUrl = `${PRCZ_API_BASE}/customers`
export const getCustomersLinkUrl = (customerId) => `${PRCZ_API_BASE}/data/accounting/customers/${customerId}/link`
export const getAccountingUrl = (customerId) => `${PRCZ_API_BASE}/data/accounting/customers/${customerId}`
export const getAccountingIntegrationsUrl = (customerId) => `${PRCZ_API_BASE}/data/accounting/customers/${customerId}/integrations`
export const getCommerceUrl = (customerId) => `${PRCZ_API_BASE}/data/commerce/customers/${customerId}`
export const getCommerceConnectionsUrl = (customerId) => `${PRCZ_API_BASE}/data/commerce/customers/${customerId}/all`
export const getCommerceIntegrationsUrl = (customerId) => `${PRCZ_API_BASE}/data/commerce/customers/${customerId}/integrations`
export const getAccountingDataStatusUrl = (customerId) => `${PRCZ_API_BASE}/data/accounting/customers/${customerId}/dataStatus`
export const getBankCustomersUrl = (customerId) => `${PRCZ_API_BASE}/data/banking/customers/${customerId}/link`
export const getBankConnectionsUrl = (customerId) => `${PRCZ_API_BASE}/data/banking/customers/${customerId}/connections`
export const getActiveContract = (customerId) => `${PRCZ_API_BASE}/financing/customers/${customerId}/contracts/active`
export const getAllContracts = (customerId) => `${PRCZ_API_BASE}/financing/customers/${customerId}/contracts`
export const topUpUrl = (customerId) => `${PRCZ_API_BASE}/financing/customers/${customerId}/topups`
export const getBankAccountsUrl = (customerId) => `${PRCZ_API_BASE}/data/banking/customers/${customerId}/accounts`
export const getVirtualCreditCardsUrl = (customerId) => `${PRCZ_API_BASE}/data/virtual-cards/customers/${customerId}/virtual-cards`
export const getVirtualCreditCardsTransactionsUrl = (customerId, virtualCreditCardId) => `${PRCZ_API_BASE}/data/virtual-cards/customers/${customerId}/virtual-cards/${virtualCreditCardId}/transactions`
export const publicLeadUrl = (customerId) => `${PRCZ_API_BASE}/financing/customers/${customerId}/leads`
export const tasksUrl = (customerId) => `${PRCZ_API_BASE}/tasks/customers/${customerId}`
export const fileUploadUrl = `${PRCZ_API_BASE}/files/signedurl`
export const deleteIntegrationConnection = (customerId, integrationType, connectionId) => `${PRCZ_API_BASE}/data/${integrationType}/customers/${customerId}/connections/${connectionId}`
export const associatePromoCodeUrl = (customerId, promoCode) => `${PRCZ_API_BASE}/promotions/customers/${customerId}/${promoCode}/associate`
export const getUserUrl = `${PRCZ_API_BASE}/users`

// In case of reauthentication, we pass the connection id to possibly update the link in BE
export const getBankCustomersUrlWithConnectionId = (customerId, connectionId) => `${PRCZ_API_BASE}/data/banking/customers/${customerId}/link/${connectionId}`