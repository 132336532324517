// ** Redux Imports
import { customersUrl, getAllContracts, leadUrl, getAccountingUrl, getBankConnectionsUrl, getCommerceConnectionsUrl, tasksUrl } from '@src/configs/api'
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'

export const releaseError = createAction('customers/releaseError')
export const setNoCustomers = createAction('customers/setNoCustomers')
export const setCustomer = createAction('customers/setCustomer')
export const createCustomer = createAction('customers/createCustomer')
export const updateLead = createAction('customers/updateLead')
export const setLead = createAction('customers/setLead')

export const getTasks = createAsyncThunk('customers/getTasks', async customerId => {
  const response = await axios.get(tasksUrl(customerId))
  return { data: response.data }
})

export const addTask = createAction('customers/addTask')

export const getCustomers = createAsyncThunk('customers/getCustomers', async () => {
  const response = await axios.get(customersUrl)

  // Fetch Customers adnd Their Active Contract
  const customers = await Promise.all(response.data.map(async (customer) => ({
    ...customer,
    contracts: (await axios.get(getAllContracts(customer.id), {
      params: {
        include: []
      }
    }))?.data
  })))

  return { data: customers }
})

export const getLead = createAsyncThunk('customers/getLead', async () => {
  const response = await axios.get(leadUrl)
  return { data: response.data }
})

export const getConnections = createAsyncThunk('customers/getConnections', async customerId => {

  //Fetch Bank Connection
  const bankConnections = (await axios.get(getBankConnectionsUrl(customerId)))?.data

  //Fetch Accounting Connection
  const accountingConnection = (await axios.get(getAccountingUrl(customerId)))?.data

  //Fetch Commerce Connections
  const commerceConnections = (await axios.get(getCommerceConnectionsUrl(customerId)))?.data

  return { bankConnections, accountingConnection, commerceConnections }
})

const initialState = {
  customers: undefined,
  financed: undefined,
  error: false,
  customersLoading: undefined,
  customer: undefined,
  lead: undefined,
  leadLoading: undefined,
  userOnboarded: undefined,
  connections: undefined,
  connectionsLoading: undefined,
  tasks: []
}

const extractFileProps = f => {
  const allParts = f.split('/')
  allParts.shift()
  return { name: allParts.join('/'), key: f }
}


export const customersSlice = createSlice({
  name: 'customers',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(releaseError, state => {
        state.error = false
        state.customersLoading = true
      })
      .addCase(getCustomers.pending, state => {
        state.customersLoading = true
      })
      .addCase(setCustomer, (state, action) => {
        state.customer = action.payload
        state.userOnboarded = state.financed || (state.lead && state.lead.status !== 'ONBOARDING') || action.payload?.contracts?.length
      })
      .addCase(setNoCustomers, state => {
        state.customers = false
        state.financed = undefined
        state.error = false
        state.customersLoading = false
        state.customer = undefined
        state.userOnboarded = undefined
        state.connections = undefined
      })
      .addCase(getCustomers.rejected, (state) => {
        state.error = true
        state.customersLoading = false
      })
      .addCase(getCustomers.fulfilled, (state, action) => {
        state.customers = action.payload.data
        state.error = false
        state.customersLoading = false
        const financed = action.payload.data.some(c => c.contracts?.some(c => ['APPROVED', 'DONE'].includes(c.status)))
        state.financed = financed
        state.leadLoading = financed ? false : undefined
        state.customer = action.payload.data[0]
        state.userOnboarded = financed || (state.lead && state.lead.status !== 'ONBOARDING') || action.payload.data[0]?.contracts?.length
      })
      .addCase(createCustomer, (state, action) => {
        const customers = [action.payload.data]
        state.customers = customers
        state.error = false
        state.customersLoading = false
        const financed = customers.some(c => c.contracts?.some(c => ['APPROVED', 'DONE'].includes(c.status)))
        state.financed = financed
        state.leadLoading = false
        state.lead = { ...state.lead, created: true }
        state.customer = customers[0]
        state.userOnboarded = financed || (state.lead && state.lead.status !== 'ONBOARDING') || customers[0].contracts?.length
      })
      .addCase(getLead.pending, state => {
        state.leadLoading = true
      })
      .addCase(getLead.rejected, (state) => {
        state.error = true
        state.leadLoading = false
      })
      .addCase(getLead.fulfilled, (state, action) => {
        state.lead = action.payload.data || false
        state.error = false
        state.leadLoading = false
        state.userOnboarded = state.financed || (action.payload.data && action.payload.data.status !== 'ONBOARDING') || state.customer?.contracts?.length
      })
      .addCase(updateLead, (state, action) => {
        state.lead = { ...state.lead, ...action.payload }
        if (action.payload?.status === 'ONBOARDED') {
          state.userOnboarded = true
        }
      })
      .addCase(setLead, (state, action) => {
        state.lead = action.payload
        state.leadLoading = false
        state.userOnboarded = state.financed || (action.payload.status !== 'ONBOARDING') || state.customer?.contracts?.length
      })
      .addCase(getConnections.pending, state => {
        state.connectionsLoading = true
      })
      .addCase(getConnections.rejected, (state) => {
        state.error = true
        state.connectionsLoading = false
      })
      .addCase(getConnections.fulfilled, (state, action) => {
        state.connections = action.payload || false
        state.error = false
        state.connectionsLoading = false
      })
      .addCase(getTasks.fulfilled, (state, action) => {
        state.tasks = action.payload.data.sort((t1, t2) => moment(t2.createdAt).diff(moment(t1.createdAt))).map(t => ({ ...t, files: t.files?.map(f => extractFileProps(f)) }))
      })
      .addCase(addTask, (state, action) => {
        state.tasks = [action.payload, ...state.tasks]
      })
  }
})

export const customersInitialState = initialState
export default customersSlice.reducer
