// ** Icons Import
import { Scale } from 'lucide-react'

export default [
  {
    id: 'accounting',
    title: 'Accounting',
    icon: <Scale size={12} />,
    navLink: '/accounting',
    action: 'read',
    resource: 'Auth'
  }
]
