const config = {
  demo: {
    apiKey: "AIzaSyDZFhLviCgtn7IqUdJTV6fgyBi03qM5FIc",
    authDomain: "precise-finance.firebaseapp.com",
    databaseURL: "https://precise-finance-default-rtdb.firebaseio.com",
    projectId: "precise-finance",
    storageBucket: "precise-finance.appspot.com",
    messagingSenderId: "883598556447",
    appId: "1:883598556447:web:4093cc8b32981d5502442a",
    measurementId: "G-TYDNVVT787"
  },
  dev: {
    apiKey: "AIzaSyBTjG_vSEUOOvkK29BxUVcCQT4j6jOE5kM",
    authDomain: "precise-dev-e5830.firebaseapp.com",
    projectId: "precise-dev-e5830",
    storageBucket: "precise-dev-e5830.appspot.com",
    messagingSenderId: "167815122405",
    appId: "1:167815122405:web:2ed7593566adf66a23b30c",
    measurementId: "G-EC4902SGM0"
  },
  prod: {
    apiKey: "AIzaSyBJI_dvObZd6xmHzTkQz_n8zBw60qb1QqM",
    authDomain: "precise-production.firebaseapp.com",
    projectId: "precise-production",
    storageBucket: "precise-production.appspot.com",
    messagingSenderId: "269662616910",
    appId: "1:269662616910:web:9b02ec4f018e17aacff21f",
    measurementId: "G-7HWGQ976HG"
  }
}

export default config