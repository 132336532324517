// ** React Imports
import { Outlet } from 'react-router-dom'

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from '@layouts/VerticalLayout'

// ** React imports
import { useEffect, useState } from 'react'

import { useSelector } from 'react-redux'

// ** Precise Footer
import Footer from '@src/layouts/components/footer'

// ** Icons Import
import { Home } from 'react-feather'

// ** Menu Items Array
import navigation from '@src/navigation/vertical'

const VerticalLayout = props => {

  // const authState = useSelector(state => state.auth)
  const { customers } = useSelector(state => state.customers)
  const [menuData, setMenuData] = useState([])

  // ** For ServerSide navigation
  useEffect(() => {
    setMenuData(
      [
        {
          id: 'dashboard',
          title: 'Dashboard',
          icon: <Home size={20} />,
          navLink: `/dashboard/financial`,
          action: 'read',
          resource: 'Auth'
        },
        ...navigation
      ])
    return () => {
      setMenuData([])
    }
  }, [customers.length])

  return (
    <Layout footer={<Footer />} menuData={menuData} {...props}>
      <Outlet />
    </Layout>
  )
}

export default VerticalLayout
