// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getBankAccountsUrl, getVirtualCreditCardsUrl, getBankConnectionsUrl } from '@src/configs/api'

// ** Axios Imports
import axios from 'axios'

export const releaseError = createAction('accounts/releaseError')

export const addBankConnection = createAction('accounts/addBankConnection')

export const getBankAccounts = createAsyncThunk('accounts/getBankAccounts', async customerId => {
    const response = await axios.get(getBankAccountsUrl(customerId))
    return { data: response.data }
})

export const getVirtualCreditCards = createAsyncThunk('accounts/getVirtualCreditCards', async customerId => {
    const response = await axios.get(getVirtualCreditCardsUrl(customerId))
    return { data: response.data }
})

export const getVirtualCreditCardsTransactions = createAsyncThunk('accounts/getVirtualCreditCardsTransactions', async (customerId, virtualCreditCardId) => {
    const response = await axios.get(getVirtualCreditCardsTransactionsUrl(customerId, virtualCreditCardId))
    return { data: response.data, id }
})

export const getBankConnections = createAsyncThunk('customers/getBankConnections', async customerId => {
    const response = await axios.get(getBankConnectionsUrl(customerId))
    return { data: response.data }
})

const groupBy = (items, key) => items.reduce(
    (result, item) => ({
        ...result,
        [item[key]]: [
            ...(result[item[key]] || []),
            item
        ]
    }),
    {}
)

const getLogoLink = (name) => `https://cdn.precise.credit/institutions/logos/${name.toLowerCase().replaceAll(/[^a-zA-Z0-9\_]+/g, "_")}.webp`


export const initialState = {
    loadingAccounts: undefined,
    bankAccounts: undefined,
    bankAccountsLength: 0,
    virtualCreditCards: undefined,
    error: false,
    bankConnections: undefined,
    bankConnectionsLoading: undefined
}

export const bankAccountsSlice = createSlice({
    name: 'accounts',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(releaseError, state => {
                state.error = false
            })
            .addCase(getBankAccounts.pending, (state) => {
                state.loadingAccounts = true
            })
            .addCase(getBankAccounts.rejected, (state) => {
                state.error = true
                state.bankAccountsLength = 0
                state.loadingAccounts = false
            })
            .addCase(getBankAccounts.fulfilled, (state, action) => {
                const mappedBankAccounts = groupBy(action.payload.data.filter(b => b.subtype === 'checking').map(b => ({ ...b, disconnected: b.ProviderConnection.status === 'AUTH_ERROR' })), 'institution')
                state.bankAccounts = mappedBankAccounts
                state.bankAccountsLength = Object.keys(mappedBankAccounts)?.length
                state.loadingAccounts = false
            })
            .addCase(getVirtualCreditCards.pending, (state) => {
                state.loadingVirtualCreditCards = true
            })
            .addCase(getVirtualCreditCards.rejected, (state) => {
                state.error = true
            })
            .addCase(getVirtualCreditCards.fulfilled, (state, action) => {
                state.virtualCreditCards = action.payload.data
            })
            .addCase(getVirtualCreditCardsTransactions.rejected, (state) => {
                state.error = true
            })
            .addCase(getVirtualCreditCardsTransactions.fulfilled, (state, action) => {
                state.virtualCreditCards = state.virtualCreditCards.map(vcc => (vcc.id === action.payload.id ? ({ ...vcc, transactions: action.payload.data }) : vcc))
            })
            .addCase(getBankConnections.pending, (state) => {
                state.bankConnectionsLoading = true
            })
            .addCase(getBankConnections.rejected, (state) => {
                state.bankConnectionsLoading = false
            })
            .addCase(getBankConnections.fulfilled, (state, action) => {
                state.bankConnectionsLoading = false
                state.bankConnections = action.payload.data.filter(bc => ['AUTH_ERROR', 'CONNECTED'].includes(bc.status)).map(bc => ({ ...bc, logo: getLogoLink(bc.institution), Accounts: bc.Accounts?.filter(b => b.subtype === 'checking')?.map(b => ({ ...b, id: b.id.toString() })) }))
            })
            .addCase(addBankConnection, (state, action) => {
                state.bankConnections = [...state.bankConnections, { ...action.payload, logo: getLogoLink(action.payload.institution) }]
            })
    }
})

export const banksInitialState = initialState
export default bankAccountsSlice.reducer
