import Tracing from './base/Tracing'
import { faroUrl } from '../configs/apiKeys'
import { getWebInstrumentations, initializeFaro } from '@grafana/faro-web-sdk'
import { TracingInstrumentation } from '@grafana/faro-web-tracing'
import { version } from '../../package.json'

class GrafanaFaroTracing extends Tracing {

    get name() {
        return 'Grafana Faro'
    }

    init() {

        const faro = initializeFaro({
            url: faroUrl,
            // apiKey: 'secret',
            instrumentations: [...getWebInstrumentations(), new TracingInstrumentation()],
            app: {
                name: 'my',
                version,
                environment: 'production'
            }
        })

        this.instance = faro
        this.instance.unpause()
    }

    trace(type, ...params) {
        console.debug('Trace', ...params)
        this.instance.api[type](...params)
    }

    remove() {
        this.instance.api.resetUser()
        this.instance.api.resetSession()
        this.instance.pause()
    }

}

export { GrafanaFaroTracing as grafanaFaroTracing }