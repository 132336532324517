// ** Icons Import
import { Tag } from 'lucide-react'

export default [
  {
    id: 'tickets',
    title: 'Tickets',
    icon: <Tag size={12} />,
    navLink: '/tickets',
    action: 'read',
    resource: 'Auth'
  }
]
