// ** React Imports
import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

// ** Icons Imports
import { Disc, X, Circle } from 'react-feather'

import { useSkin } from '@hooks/useSkin'

// ** Config
import themeConfig from '@configs/themeConfig'

const VerticalMenuHeader = props => {
  // ** Props
  const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover } = props
  // eslint-disable-next-line no-unused-vars
  const { skin, setSkin } = useSkin()
  const [logo, setLogo] = useState(themeConfig.app.appLogoImage)

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) {
      setGroupOpen([])
      setLogo(skin === 'dark' ? themeConfig.app.appLogoImageSmallDark : themeConfig.app.appLogoImageSmall)
    } else {
      setLogo(skin === 'dark' ? themeConfig.app.appLogoImageDark : themeConfig.app.appLogoImage)
    }

  }, [menuHover, menuCollapsed, skin])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour='toggle-icon'
          className='text-dark toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour='toggle-icon'
          className='text-dark toggle-icon d-none d-xl-block'
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (
    <div className='navbar-header'>
      <ul className='nav navbar-nav flex-row'>
        <li className='nav-item me-auto'>
          <NavLink to='/' className='navbar-brand'>
            <span className='brand-logo'>
              <img className={!menuHover && menuCollapsed ? 'collapsed' : ''} src={logo} alt='logo' />
            </span>
          </NavLink>
        </li>
        <li className='nav-item nav-toggle'>
          <div className='nav-link modern-nav-toggle cursor-pointer'>
            <Toggler />
            <X onClick={() => setMenuVisibility(false)} className='toggle-icon icon-x d-block d-xl-none' size={20} />
          </div>
        </li>
      </ul>
    </div>
  )
}

export default VerticalMenuHeader
