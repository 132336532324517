class Tracing {

    constructor({ enabled = true } = {}) {
        this.enabled = enabled
        console.debug(`Using ${this.name} as AnalyticsProvider, set to ${this.enabled ? 'enabled' : 'disabled'}`)
    }

    get name() {
        return 'Analytics'
    }

    // eslint-disable-next-line no-unused-vars
    init(instance) {
        console.debug('init')
        throw new ('Not implemented')
    }

    trace(payload) {
        console.debug('payload', payload)
        throw new ('Not implemented')
    }

    remove() {
        console.debug('remove')
        throw new ('Not implemented')
    }

}

export default Tracing