// ** Core JWT Import
import FirebaseAuthService from './FirebaseAuthService'

function useJwt() {
  const jwt = new FirebaseAuthService()

  return { jwt }
}

const { jwt } = useJwt()

export default jwt
