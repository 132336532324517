// ** React Lazy withou flickering
import { lazy } from 'react-lazy-no-flicker'

const BankAccounts = lazy(() => import('../../views/bank-accounts'))
const Authenticate = lazy(() => import('../../views/bank-accounts/authenticate'))
const OAuthLink = lazy(() => import('../../views/pages/authentication/OAuthLink'))


const BankAccountsRoutes = [
  // Bank Accounts
  {
    path: '/bank-accounts',
    element: <BankAccounts />,
    exact: true,
    meta: {
      secured: true
    }
  },
  {
    path: '/bank-accounts/:connectionId/authenticate',
    element: <Authenticate />,
    exact: true,
    meta: {
      secured: true,
      authRoute: true,
      oAuth: true,
      layout: 'blank'
    }
  },
  {
    path: '/bank-accounts/oauth',
    element: <OAuthLink />,
    meta: {
      secured: true,
      authRoute: true,
      oAuth: true,
      layout: 'blank'
    }
  },
  {
    path: '/bank-accounts/virtual-cards/:virtualCreditCardId',
    element: <BankAccounts />,
    exact: true,
    meta: {
      secured: true
    }
  }
]

export default BankAccountsRoutes
