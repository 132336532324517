// ** React Lazy withou flickering
import { lazy } from 'react-lazy-no-flicker'
const DashboardFinancial = lazy(() => import('../../views/dashboard/financial'))
const DashboardRoutes = [
  {
    path: '/dashboard/financial',
    element: <DashboardFinancial />,
    meta: {
      navLink: '/dashboard/finance',
      secured: true
    }
  }
]

export default DashboardRoutes
