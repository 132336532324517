// ** React Lazy withou flickering
import { lazy } from 'react-lazy-no-flicker'

const Connect = lazy(() => import('../../views/connect'))

const ConnectRoutes = [
  // Codat
  {
    path: '/connect',
    element: <Connect />,
    meta: {
      secured: true,
      authRoute: true,
      oAuth: true,
      layout: 'blank'
    }
  }
]

export default ConnectRoutes
