// ** Redux Imports
import { createSlice, createAction } from '@reduxjs/toolkit'

export const addNotification = createAction('notifications/addNotification')
export const hideBadge = createAction('notifications/hideBadge')

const initialState = {
  notifications: [],
  showBadge: false
}

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(addNotification, (state, action) => {
        state.notifications = [action.payload, ...state.notifications]
        state.showBadge = true
      })
      .addCase(hideBadge, state => {
        state.showBadge = false
      })
  }
})

export const notificationsInitialState = initialState
export default notificationsSlice.reducer
