// ** React Lazy withou flickering
import { lazy } from 'react-lazy-no-flicker'

const Tickets = lazy(() => import('../../views/tickets'))

const TicketsRoutes = [
  // Tickets
  {
    path: '/tickets',
    element: <Tickets />,
    meta: {
      secured: true
    }
  }
]

export default TicketsRoutes
