// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth, { authInitialState } from './authentication'
import accounting, { accountingInitialState } from './accounting'
import commerce, { commerceInitialState } from './commerce'
import dashboard, { dashboardInitialState } from '@src/views/dashboard/financial/store'
import bankAccounts, { banksInitialState } from './bankAccounts'
import customers, { customersInitialState } from './customers'
import promotions, { promotionsInitialState } from './promotions'
import notifications, { notificationsInitialState } from './notifications'
import { combineReducers } from 'redux'

const initialState = {
  auth: authInitialState,
  dashboard: dashboardInitialState,
  bankAccounts: banksInitialState,
  customers: customersInitialState,
  accounting: accountingInitialState,
  commerce: commerceInitialState,
  promotions: promotionsInitialState,
  notifications: notificationsInitialState
}

const combinedReducer = combineReducers({
  auth,
  layout,
  navbar,
  dashboard,
  customers,
  bankAccounts,
  accounting,
  commerce,
  promotions,
  notifications
})

const rootReducer = (state, action) => {
  if (action.type === 'authentication/handleLogout') {
    state = { ...state, ...initialState }
  }
  return combinedReducer(state, action)
}

export default rootReducer
