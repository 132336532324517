// ** Icons Import
import { ShoppingBag } from 'lucide-react'

export default [
  {
    id: 'commerce',
    title: 'Commerce',
    icon: <ShoppingBag size={12} />,
    navLink: '/commerce',
    action: 'read',
    resource: 'Auth'
  }
]
