import { createContext, useContext } from 'react'
import { segmentAnalytics } from '../../analytics/SegmentAnalytics'

// const AnalyticsID = ''

const Analytics = createContext()
const defaultAnalytics = segmentAnalytics

const AnalyticsContext = (props) => {

    const provider = new (props.type || defaultAnalytics)(props)

    const initAnalytics = (instance) => {
        if (!provider.enabled) {
            return
        }
        provider.init(instance)
    }

    const logEvent = (payload) => {
        if (!provider.enabled) {
            return
        }
        provider.logEvent(payload)
    }

    const removeAnalytics = () => {
        if (!provider.enabled) {
            return
        }
        provider.remove()
    }

    return <Analytics.Provider value={{ initAnalytics, logEvent, removeAnalytics }} {...props} />
}

const useAnalytics = () => useContext(Analytics)

export { AnalyticsContext, useAnalytics }