const Footer = () => {
    return (
        <p className='clearfix mb-0 font-small-3'>
            <span className='float-md-start d-block d-md-inline-block mt-25'>
                Copyright © {new Date().getFullYear()}{' '}
                <a href='https://precise.credit' target='_blank' rel='noopener noreferrer'>
                    Precise Finance
                </a>
            </span>
        </p>
    )
}

export default Footer