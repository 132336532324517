// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getCommerceIntegrationsUrl, getCommerceUrl } from '@src/configs/api'

// ** Axios Imports
import axios from 'axios'

export const releaseError = createAction('commerce/releaseError')
export const selectIntegration = createAction('commerce/selectIntegration')

export const getCommerceIntegrations = createAsyncThunk('commerce/getCommerceIntegrations', async customerId => {
    const response = await axios.get(getCommerceIntegrationsUrl(customerId))
    return { data: response.data }
})

export const addCommerceConnection = createAsyncThunk('commerce/addCommerceConnection', async (data) => {
    const response = await axios.post(getCommerceUrl(data.customerId), data)
    return { data: response.data }
})

export const initialState = {
    integrations: undefined,
    availableIntegrations: undefined,
    selectedIntegration: undefined,
    awaitingLinkIntegration: undefined,
    error: undefined,
    inProgress: undefined
}

export const commerceSlice = createSlice({
    name: 'commerce',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(releaseError, state => {
                state.error = undefined
            })
            .addCase(getCommerceIntegrations.rejected, (state) => {
                state.error = 'Failed to load Commerce Platforms'
            })
            .addCase(getCommerceIntegrations.fulfilled, (state, action) => {
                state.error = undefined
                state.integrations = action.payload.data.filter(i => !!i.linked)
                state.availableIntegrations = action.payload.data.filter(i => !i.linked)
                state.awaitingLinkIntegration = action.payload.data.filter(i => !i.linked && !!i.linkUrl)[0]
            })
            .addCase(addCommerceConnection.pending, (state) => {
                state.inProgress = true
                state.error = undefined
                state.awaitingLinkIntegration = undefined
            })
            .addCase(addCommerceConnection.rejected, (state) => {
                state.error = 'Failed to link Commerce Platform'
                state.inProgress = false
            })
            .addCase(addCommerceConnection.fulfilled, (state, action) => {
                state.error = undefined
                state.awaitingLinkIntegration = action.payload.data
                state.inProgress = false
            })
            .addCase(selectIntegration, (state, action) => {
                state.selectedIntegration = action.payload
            })
    }
})

export const commerceInitialState = initialState
export default commerceSlice.reducer
