// ** Icons Import
import { Landmark } from 'lucide-react'

export default [
  {
    id: 'bank_accounts',
    title: 'Banking',
    icon: <Landmark size={12} />,
    navLink: '/bank-accounts',
    action: 'read',
    resource: 'Auth'
  }
]
