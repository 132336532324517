const gglMapsKey = 'AIzaSyCi61TVfsmcliteR0UKPHW74eP4Xtt10ZA'

const SEGMENT_WRITE_KEY = {
  demo: 'eqgx3ypZk5nSD5hHTC6QMuSgeWE918zc',
  dev: 'eqgx3ypZk5nSD5hHTC6QMuSgeWE918zc',
  prod: 'gs7jRG12Q3lnGiSY37JgXxmR0qfuMSPg'
}

const segmentWriteKey = SEGMENT_WRITE_KEY[process.env.REACT_APP_PRCZ_ENV]
// const segmentWriteKey = 'gs7jRG12Q3lnGiSY37JgXxmR0qfuMSPg'

const faroUrl = 'https://faro-collector-prod-us-central-0.grafana.net/collect/b0638a884dcdf7505a650001da56990c'

export {
  gglMapsKey,
  segmentWriteKey,
  faroUrl
}