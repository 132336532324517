// ** React Lazy withou flickering
import { lazy } from 'react-lazy-no-flicker'

const Commerce = lazy(() => import('../../views/commerce'))

const CommerceRoutes = [
  // Commerce
  {
    path: '/commerce',
    element: <Commerce />,
    meta: {
      secured: true
    }
  }
]

export default CommerceRoutes
