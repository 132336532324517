// ** Redux Imports
import { associatePromoCodeUrl } from '@src/configs/api'
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import axios from 'axios'

export const setPromoCode = createAction('promotions/setPromoCode')
export const associatePromoCode = createAsyncThunk('promotions/associatePromoCode', async ({ customerId, promoCode }) => {

  console.info('Applying Promo Code', promoCode)

  const response = await axios.post(associatePromoCodeUrl(customerId, promoCode))
  return { data: response.data }
})

const initialState = {
  promoCode: undefined,
  associatingPromoCode: false,
  associatePromoCodeError: false,
  associatedPromoCode: undefined
}

export const promotionsSlice = createSlice({
  name: 'promotions',
  initialState,
  extraReducers: builder => {
    builder
      .addCase(setPromoCode, (state, action) => {
        state.promoCode = action.payload

        // Remove the promoCode from the localStorage
        window.localStorage.removeItem('prcz-promoCode')

        // Remove the promoCode from the url
        const search = new URLSearchParams(window.location.search)
        search.delete('promoCode')
        window.history.replaceState({}, '', `${window.location.pathname}?${search.toString()}`)

      })
      .addCase(associatePromoCode.pending, state => {
        state.associatingPromoCode = true
        state.associatePromoCodeError = false
      })
      .addCase(associatePromoCode.rejected, (state) => {
        state.associatingPromoCode = false
        state.associatePromoCodeError = true
      })
      .addCase(associatePromoCode.fulfilled, state => {
        state.associatingPromoCode = false
        state.associatedPromoCode = state.promoCode
        state.promoCode = undefined

        console.info(`promo code ${state.associatedPromoCode} fulfilled`)

      })
  }
})

export const promotionsInitialState = initialState
export default promotionsSlice.reducer
