import { proxyUrl } from './api'
import { UnleashClient } from 'unleash-proxy-client'

const unleashClient = new UnleashClient({
  url: proxyUrl,
  clientKey: 'precise-unleashed',
  refreshInterval: 15,
  appName: 'precise',
  environment: process.env.REACT_APP_PRCZ_ENV
})

export { unleashClient }