// ** React Lazy withou flickering
import { lazy } from 'react-lazy-no-flicker'

const Login = lazy(() => import('../../views/pages/authentication/LoginV1'))
const Register = lazy(() => import('../../views/pages/authentication/Register'))
const ForgotPassword = lazy(() => import('../../views/pages/authentication/ForgotPasswordBasic'))
const Error = lazy(() => import('../../views/pages/misc/Error'))
const Maintenance = lazy(() => import('../../views/pages/misc/Maintenance'))
const NotAuthorized = lazy(() => import('../../views/pages/misc/NotAuthorized'))

// Register Routes
const Registration = lazy(() => import('../../views/pages/authentication/RegisterV1'))
const RegisterMoreThenMillion = lazy(() => import('../../views/pages/authentication/RegisterMoreThenMillion'))
const RegisterTime = lazy(() => import('../../views/pages/authentication/RegisterTime'))
const RegisterAmount = lazy(() => import('../../views/pages/authentication/RegisterAmount'))
const RegisterBank = lazy(() => import('../../views/pages/authentication/RegisterBank'))
const RegisterCompany = lazy(() => import('../../views/pages/authentication/RegisterCompany'))
const RegisterAccounting = lazy(() => import('../../views/pages/authentication/RegisterAccounting'))
const RegisterCommerce = lazy(() => import('../../views/pages/authentication/RegisterCommerce'))
const RegisterComplete = lazy(() => import('../../views/pages/authentication/RegisterComplete'))

const PagesRoutes = [
  {
    path: '/login',
    element: <Login />,
    meta: {
      authRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/register/*',
    element: <Register />,
    children: [
      { path: "register", element: <Registration /> },
      { path: "amount", element: <RegisterAmount /> },
      { path: "company", element: <RegisterCompany /> },
      { path: "time", element: <RegisterTime /> },
      { path: "million", element: <RegisterMoreThenMillion /> },
      { path: "bank", element: <RegisterBank /> },
      { path: "accounting", element: <RegisterAccounting /> },
      { path: "commerce", element: <RegisterCommerce /> },
      { path: "complete", element: <RegisterComplete /> }
    ],
    meta: {
      authRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
    meta: {
      authRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/misc/not-authorized',
    element: <NotAuthorized />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/misc/maintenance',
    element: <Maintenance />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  },
  {
    path: '/misc/error',
    element: <Error />,
    meta: {
      publicRoute: true,
      layout: 'blank'
    }
  }
]

export default PagesRoutes
