// ** Redux Imports
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit'
import { getAllContracts, topUpUrl } from '@src/configs/api'

// ** Axios Imports
import axios from 'axios'
import moment from 'moment'

import { normalizeCurrency, toShortDate } from '@utils'

// ** Icons Imports
import * as Icon from 'react-feather'

export const releaseError = createAction('dashboardFinancial/releaseError')
export const releaseTopupError = createAction('dashboardFinancial/releaseTopupError')
export const setInProgress = createAction('dashboardFinancial/setInProgress')

export const getContracts = createAsyncThunk('dashboardFinancial/getContracts', async customerId => {
  const response = await axios.get(getAllContracts(customerId), {
    params: {
      include: ['Terms', 'Fees', 'Lead', 'Payments', 'PaymentTransactions', 'CollectionRequests', 'ESignContracts']
    }
  })
  return { data: response.data }
})

export const getTopup = createAsyncThunk('dashboardFinancial/getTopup', async (customerId, { rejectWithValue }) => {
  try {
    const response = await axios.get(topUpUrl(customerId))
    return { data: response.data }
  } catch (err) {
    if (!err.response) {
      throw err
    }

    return rejectWithValue(err.response.data)
  }
})

const getRepaidData = data => {

  // Reduce the accumulative collected amount, and calculate by factorRate 
  const allTransactions = data
    .map(contract => contract.PaymentTransactions.filter(pt => pt.paymentType !== 'FUNDING').sort((c1, c2) => c1.createdAtOriginal - c2.createdAtOriginal))
    .flat()
    .sort((c1, c2) => c1[0] - c2[0]) || []
      .reduce

  let collected = 0, lastIndex = -1
  const alreadyProcessed = {}

  return allTransactions.reduce((seriesData, currentTransaction) => {
    const amount = currentTransaction.paymentType === 'REFUND' ? (-currentTransaction.amount) : currentTransaction.amount
    collected += amount
    if (!alreadyProcessed[currentTransaction.createdAt]) {
      alreadyProcessed[currentTransaction.createdAt] = true
      lastIndex++
      seriesData.push([currentTransaction.createdAt, collected, amount])
    } else {
      seriesData[lastIndex][1] = collected
      seriesData[lastIndex][2] += amount
    }
    return seriesData
  }, [])
}

const sortedMappedTransactions = (transactions) => transactions
  .map(cr => ({
    ...cr,
    createdAt: moment(cr.createdAt).set('hour', 12).set('minute', 0).set('second', 0).set('millisecond', 0).valueOf(),
    createdAtOriginal: moment(cr.createdAt).valueOf()
  }))
  .sort((c1, c2) => c1.createdAtOriginal - c2.createdAtOriginal)

const createTransaction = (id, createdAt, amount, transactionId, processing, paymentType, collectionRequest) => ({
  id,
  transactionId,
  title: toShortDate(createdAt),
  color: paymentType === 'COLLECTION' ? 'light-danger' : 'light-success',
  subtitle: processing ? 'Processing...' : `Transaction ID: ${transactionId}`,
  amount: `${paymentType === 'COLLECTION' ? '-' : '+'} ${normalizeCurrency(amount)}`,
  Icon: Icon[paymentType === 'COLLECTION' ? 'Check' : paymentType === 'REFUND' ? 'RefreshCw' : paymentType === 'PROMOTION' ? 'Gift' : 'DollarSign'],
  createdAt,
  paymentType,
  processing,
  description: collectionRequest?.description,
  collectionRequestId: collectionRequest?.id,
  incomeTransactions: collectionRequest?.IncomeTransactions,
  down: paymentType === 'COLLECTION'
})

export const initialState = {
  contracts: false,
  lastContract: false,
  repaidSeries: [],
  repaidTotal: 0,
  transactions: [],
  topup: {
    maxAvailable: 0,
    available: 0,
    owed: 0,
    inProgress: 0,
    error: false,
    loading: true
  },
  contractsError: false,
  contractsLoading: false
}

export const dashboardFinancialSlice = createSlice({
  name: 'dashboardFinancial',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(releaseError, state => {
        state.contractsError = false
        state.contractsLoading = false
      })
      .addCase(releaseTopupError, state => {
        state.topup.error = false
        state.topup.loading = false
      })
      .addCase(setInProgress, (state, action) => {
        state.topup.inProgress = action.payload
      })
      .addCase(getTopup.pending, (state) => {
        if (!state.topup?.hasOwnProperty('available') && !state.topup?.hasOwnProperty('inProgress')) {
          state.topup.loading = true
        }
      })
      .addCase(getTopup.rejected, (state) => {
        state.topup.error = true
        state.topup.loading = false
      })
      .addCase(getTopup.fulfilled, (state, action) => {
        const { maxAvailable, owed, inProgressAmount } = action.payload.data
        state.topup = inProgressAmount ? {
          inProgress: inProgressAmount,
          loading: false
        } : {
          maxAvailable,
          available: Math.max(maxAvailable - owed, 0),
          owed,
          loading: false
        }
      })
      .addCase(getContracts.pending, (state) => {
        state.contractsLoading = true
      })
      .addCase(getContracts.rejected, (state) => {
        state.contractsError = true
        state.contractsLoading = false
      })
      .addCase(getContracts.fulfilled, (state, action) => {
        state.contractsLoading = false
        const mappedContracts = action.payload.data
          .filter(contract => ['TOPUPPED', 'APPROVED', 'DONE'].includes(contract.status))
          .map(contract => ({
            ...contract,
            PaymentTransactions: sortedMappedTransactions(contract.PaymentTransactions)
          }))
          .sort((c1, c2) => moment(c1.approvedAt).diff(moment(c2.approvedAt)))

        const flatTransactions = mappedContracts
          .map(contract => contract.PaymentTransactions)
          .flat()
          .reduce((acc, { id, createdAtOriginal, amount, transactionId, status, paymentType, CollectionRequest }) => {

            if (!CollectionRequest) {
              return { ...acc, [`p${id}`]: { uid: `p${id}`, id, createdAtOriginal, amount, transactionId, status, paymentType, CollectionRequest } }
            }
            if (acc[CollectionRequest.id]) {
              acc[CollectionRequest.id].amount += amount
              acc[CollectionRequest.id].transactions.push({ id, createdAtOriginal, amount, transactionId, status })
              return acc
            } else {
              return {
                ...acc, [CollectionRequest.id]: { uid: `c${CollectionRequest.id}`, id: CollectionRequest.id, createdAtOriginal, amount, transactionId: CollectionRequest.id, status: CollectionRequest.status, paymentType, CollectionRequest, transactions: [{ id, status, amount, transactionId, createdAtOriginal }] }
              }
            }
          }, {})

        const mappedTransactions = Object.values(flatTransactions)
          ?.map(pt => createTransaction(pt.id, pt.createdAtOriginal, pt.amount, pt.transactionId, pt.status === 'PROCESSING', pt.paymentType, pt.CollectionRequest))
          ?.sort((c1, c2) => c2.createdAt - c1.createdAt) || []

        const repaidSeries = getRepaidData(mappedContracts)

        state.lastContract = action.payload.data.sort((c1, c2) => moment(c2.createdAt).diff(moment(c1.createdAt)))[0]
        state.transactions = mappedTransactions
        state.contracts = mappedContracts
        state.repaidSeries = repaidSeries
        state.repaidTotal = mappedContracts?.reduce(((repaidTotal, currentContract) => repaidTotal + currentContract.CollectedAmount), 0) || 0
      })
  }
})

export const dashboardInitialState = initialState
export default dashboardFinancialSlice.reducer
