import Analytics from './base/Analytics'
class SegmentAnalytics extends Analytics {

    get name() {
        return 'Segment Analytics'
    }

    init(instance) {
        this.instance = instance
    }

    logEvent(payload) {
        console.debug('Event Log:', payload)
        switch (payload.type) {
            case 'identify':
                this.identify(payload)
                break
            case 'track':
                this.track(payload)
                break
            case 'page':
                this.page(payload)
                break
            default:
                break
        }
    }

    identify(payload) {
        const { id, traits, globalIntegrations } = payload
        this.instance.identify(id, traits, globalIntegrations)
    }

    page(payload) {
        const { category, properties } = payload
        this.instance.page(category, properties.title, properties)
    }

    track(payload) {
        const { event, properties } = payload
        this.instance.track(event, properties)
    }

    remove() {
        this.instance.reset()
    }

}

export { SegmentAnalytics as segmentAnalytics }